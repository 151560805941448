/**
 * Hash listener
 */

export default class HashListener {
  constructor({ onChange = () => {} }) {
    this.init(onChange);
  }
  init = (onChange) => {
    var self = this;
    window.addEventListener("hashchange", () => {
      var h = this.getHash();
      if (h != self.hash) {
        self.hash = h;
        onChange(utils.decodeURILocal(h).replace("#", ""), this.base64);
        this.base64 = null;
      }
    });
  };
  setPath = (s, dir, base64) => {
    if (!s || typeof s !== "string") return;
    const extension = dir ? false : utils.getFileExtension(s);
    const path = extension ? s : s.endsWith("/") ? s : s + "/";

    if (base64) {
      this.base64 = base64;
    }

    document.location.hash = path;
    // if (document.location.hash != "#" + path)
    //   document.location.hash = utils.encodeURILocal(path); //fix for Firefox refusing some strings...umlauts
  };
  getPath = () => {
    const h = this.getHash();
    return utils.decodeURILocal(h).replace("#", "") || "/";
  };
  getHash = () => {
    return document.location.hash;
  };
  syncHash = () => {
    var s = this.hash;
    if (s != this.getHash()) {
      document.location.hash = s;
    }
  };
}
